@import "colors";

/*Main Banner*/

.main-banner {
    position: relative;
    padding-top: 75px;
    z-index: 1;
    .bg-round-layer {
        position: absolute;
        right: -150px;
        top: 70px;
        width: 864px;
        height: 864px;
        display: block;
        background: url(images/background/round-bg.png) center center no-repeat;
    }
    .content-column {
        position: relative;
        .inner-column {
            position: relative;
            padding-top: 240px;
        }
        h2 {
            position: relative;
            color: #444444;
            font-size: 50px;
            font-weight: 400;
            line-height: 1.3em;
        }
        .text {
            position: relative;
            color: #7f8497;
            font-size: 16px;
            margin-top: 15px;
            line-height: 1.8em;
            margin-bottom: 32px;
            padding-right: 70px;
        }
    }
    .image-column {
        .inner-column {
            .icon-one {
                position: absolute;
                left: 10%;
                bottom: 16%;
                width: 50px;
                height: 50px;
                color: #ffffff;
                text-align: center;
                border-radius: 50%;
                line-height: 50px;
                font-size: 26px;
                display: inline-block;
                background-color: #b276f3;
            }
            .icon-two {
                position: absolute;
                right: -18%;
                top: 20%;
                width: 64px;
                height: 64px;
                color: #ffffff;
                text-align: center;
                border-radius: 50%;
                line-height: 64px;
                font-size: 20px;
                display: inline-block;
                background-color: #fe3250;
            }
            .icon-three {
                position: absolute;
                right: -39%;
                bottom: 16%;
                width: 37px;
                height: 37px;
                color: #ffffff;
                text-align: center;
                border-radius: 50%;
                line-height: 50px;
                font-size: 26px;
                display: inline-block;
                background-color: #ffffff;
                border: 12px solid #6363ec;
            }
            .icon-four {
                position: absolute;
                left: 42%;
                top: 18%;
                width: 40px;
                height: 40px;
                color: #ffffff;
                text-align: center;
                border-radius: 50%;
                display: inline-block;
                border: 1px solid #20f2f9;
                background-color: rgba(32, 242, 249, 0.1);
                &:before {
                    position: absolute;
                    content: "";
                    left: 10px;
                    top: 10px;
                    right: 10px;
                    bottom: 10px;
                    border-radius: 50%;
                    background-color: #20f2f9;
                }
            }
            .icon-five {
                position: absolute;
                left: 25%;
                bottom: 30%;
                width: 37px;
                height: 37px;
                color: #ffffff;
                text-align: center;
                border-radius: 50%;
                line-height: 50px;
                font-size: 26px;
                display: inline-block;
                background-color: #ffffff;
                border: 12px solid #21ffaf;
            }
            position: relative;
            .circle-one {
                position: absolute;
                left: 0px;
                top: 0px;
                right: 0px;
                bottom: 0px;
                height: 820px;
                width: 860px;
                border-radius: 50%;
                border: 1px solid #f3f3f3;
            }
            .circle-two {
                position: absolute;
                left: 130px;
                top: 110px;
                right: 130px;
                bottom: 130px;
                height: 610px;
                width: 610px;
                border-radius: 50%;
                border: 1px solid #f3f3f3;
            }
            .circle-three {
                position: absolute;
                left: 260px;
                top: 250px;
                right: 130px;
                bottom: 130px;
                height: 350px;
                width: 350px;
                border-radius: 50%;
                border: 1px solid #f3f3f3;
            }
            .circle-four {
                position: absolute;
                left: 370px;
                top: 360px;
                right: 130px;
                bottom: 130px;
                height: 130px;
                width: 130px;
                border-radius: 50%;
                border: 1px solid #f3f3f3;
            }
            .image {
                position: relative;
                padding-top: 0px;
            }
        }
        position: relative;
        z-index: 1;
        .image img {
            position: relative;
            max-width: none;
            display: inline-block;
        }
    }
}


/***

  ====================================================================
      Banner Section Two
  ====================================================================

  ***/

.container-section-two {
    position: relative;
}

.video-main-train {
    position: fixed;
    filter: url("images/filters.svg#grain");
}

.banner-section-two {
    position: relative;
    padding-top: 290px;
    z-index: 1;
    text-align: center;
    .content-column {
        position: relative;
        .inner-column {
            position: relative;
        }
        h2 {
            position: relative;
            color: #444444;
            font-size: 50px;
            font-weight: 400;
            line-height: 1.3em;
            margin-bottom: 40px;
        }
    }
    .image-box {
        position: relative;
        z-index: 1;
        margin-top: 50px;
        .image {
            position: relative;
            img {
                position: relative;
                display: inline-block;
            }
        }
    }
}


/* Main Banner Three */

.banner-section-three {
    position: relative;
    padding-top: 200px;
    z-index: 1;
    &:before {
        position: absolute;
        content: "";
        left: 0px;
        bottom: -60px;
        width: 100%;
        height: 227px;
    }
    &.style-two:before {
        display: none;
    }
    &.style-three {
        margin-bottom: 215px;
    }
    .content-column {
        position: relative;
        .inner-column {
            position: relative;
            padding-top: 160px;
        }
        h2 {
            position: relative;
            color: #444444;
            font-size: 40px;
            font-weight: 400;
            line-height: 1.3em;
        }
        .text {
            position: relative;
            color: #7f8497;
            font-size: 16px;
            margin-top: 22px;
            line-height: 1.9em;
            padding-right: 60px;
        }
    }
    .image-column {
        position: relative;
        z-index: 1;
        .image {
            position: relative;
            img {
                position: relative;
                max-width: none;
                right: 100px;
                display: inline-block;
            }
        }
    }
}