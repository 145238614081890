/*** 
  
  ====================================================================
      Main Header style
  ====================================================================
  
  ***/

.main-header {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 99;
    width: 100%;
    padding: 0px;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    &.fixed-header {
        top: 0px;
    }
    .logo-outer .logo img {
        position: relative;
        display: inline-block;
        max-width: 100%;
    }
    .header-upper .upper-right {
        position: relative;
        transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -webkit-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
    }
    &.header-style-one .header-upper .upper-right {
        margin-top: 38px;
    }
    .header-lower {
        position: relative;
        top: 0px;
        width: 100%;
        z-index: 5;
    }
    .nav-outer {
        position: relative;
    }
    &.header-style-two {
        .search-box-btn,
        .language>a,
        .outer-box .nav-btn {
            color: #ffffff;
        }
        .search-box-btn {
            border-color: rgba(255, 255, 255, 0.4);
        }
    }
    .sticky-header .main-menu .navigation>li>a {
        color: #ffffff;
        padding: 20px 0px;
        line-height: 30px;
        font-size: 16px;
        font-weight: 500;
        min-height: 0px;
        background: none;
        border: 0px;
        font-family: $font_primary, sans-serif;
    }
}

.header-style-three .main-menu .navigation>li {
    margin-left: 0px;
    margin-right: 1rem;
}

.header-style-two.fixed-header {
    background: #202020;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-style-two .main-menu .navigation>li>a {
    color: #ffffff;
}