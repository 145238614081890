@import url(https://fonts.googleapis.com/css?family=Raleway:400,800,200);
body {
  background-color: #ffffff;
}

#loader-container {
  width: 200px;
  height: 200px;
  color: rgb(82, 82, 82);
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 5px solid #ffffff;
  border-radius: 50%;
  -webkit-animation: borderScale 1s infinite ease-in-out;
  animation: borderScale 1s infinite ease-in-out;
}

#loadingText {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes borderScale {
  0% {
    border: 5px solid rgb(82, 82, 82);;
  }
  50% {
    border: 25px solid #ffffff;
  }
  100% {
    border: 5px solid rgb(82, 82, 82);;
  }
}

@keyframes borderScale {
  0% {
    border: 5px solid rgb(82, 82, 82);;
  }
  50% {
    border: 25px solid #ffffff;
  }
  100% {
    border: 5px solid rgb(82, 82, 82);;
  }
}
