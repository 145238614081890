// Color system
// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-50: #f3f3f3 !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-850: #333333 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
//Color-ownedFs
$color_bg_primary: #e9f0ff;
$color_primary: #f76400;
$color_secondary: #4ba2ea;
$color_purple: #5b198e;
//theme colors
$theme-colors: (
  "white": $white,
  "gray-50": $gray-50,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-850": $gray-850,
  "gray-900": $gray-900,
  "black": $black,
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "color_bg_primary": $color_bg_primary,
  "color_primary": $color_primary,
  "color_secondary": $color_secondary,
  "color_purple": $color_purple,
);
@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background-color: $value;
  }
}
