@import "./colors";

@each $color, $value in $theme-colors {
  .text-bg-fade {
    background-color: $gray-850;
    color: $gray-50;
    padding: 0;
  }
  .box-#{$color} {
    box-shadow: $value 1rem 1rem;
  }
}
.justify-words{
  &.major-text-left{

    h3{
      text-align: left;
    }
  }
  p{
    text-align: justify;
  }
}

