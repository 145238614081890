@import "colors";

.btn {
    text-decoration: none;
    outline: none;
}

.btn:focus {
    box-shadow: none;
}

/*Btn Style One*/

.btn-style-one {
    position: relative;
    line-height: 29px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    border-radius: 50px;
    letter-spacing: 1px;
    background: none;
    border: 2px solid #ffffff;
    padding: 10px 28px 10px;
    -moz-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

    &:hover {
        color: #79BF40;
        background: #ffffff;

        .icon {
            color: #79BF40;
        }
    }
}

/*Btn Style Two*/

.btn-style-two {
    position: relative;
    line-height: 29px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    border-radius: 50px;
    letter-spacing: 1px;
    background: #25C611;
    padding: 12px 30px 12px;
    border: 2px solid #25C611;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

    .icon {
        position: relative;
        color: #ffffff;
        margin-left: 10px;
        font-size: 30px;
        top: 6px;
        line-height: 10px;
        display: inline-block;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
    }

    &:hover {
        color: $white;
        background: darken($color: #125c09, $amount: 0.2);
        .icon {
            color: #25C611;
        }
    }
}

/* Btn Style Three */

.btn-style-three {
    position: relative;
    line-height: 29px;
    color: $color_primary;
    font-size: 15px;
    font-weight: 600;
    border-radius: 50px;
    letter-spacing: 1px;
    background: none;
    padding: 14px 30px 14px;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

    .icon {
        position: relative;
        color: $color_primary;
        margin-left: 10px;
        font-size: 30px;
        top: 6px;
        line-height: 10px;
        display: inline-block;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
    }

    :hover {
        color: #ffffff;
        background-color: $color_primary;
        border-color: $color_primary;

        .icon {
            color: #ffffff;
        }
    }
}

/* Btn Style Four */

.btn-style-four {
    position: relative;
    line-height: 29px;
    color: #ffffff !important;
    font-size: 15px;
    font-weight: 600;
    border-radius: 50px;
    letter-spacing: 1px;
    background: $color_primary;
    padding: 12px 53px 12px;
    border: 2px solid $color_primary;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    &:hover {
        color: #ffffff;
        border-color: $color_secondary;
        background: $color_secondary;
    };
    &:disabled{
        background-color: $gray-600;
        border: $gray-600;

    }
}