@import 'colors';
@import 'fonts';
@import 'banners';
@import 'headers';

.no-padding {
  padding: 0px !important;
}

.text {
  position: relative;
  line-height: 1.8em;
}

.strike-through {
  text-decoration: line-through;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}

/*List Style One*/

.list-style-one {
  position: relative;
  li {
    position: relative;
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    span {
      position: relative;
      color: #222222;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}

.theme-btn {
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.grey-bg {
  background-color: #f1f2f3;
}

.theme_color {
  color: $color_secondary;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(images/icons/preloader.svg);
}

/***

  ====================================================================
      Scroll To Top style
  ====================================================================

  ***/

.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 72px;
  height: 70px;
  color: #ffffff;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 65px;
  text-align: center;
  z-index: 99999;
  cursor: pointer;
  background: $color_secondary;
  display: none;
  border: 2px solid $color_secondary;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  &:hover {
    color: #ffffff;
    background: darken($color_secondary, 10%);
    border-color: darken($color_secondary, 10%);
  }
}

/*Language Dropdown*/

.main-header .language {
  position: relative;
  line-height: 1em;
  display: inline-block;
  margin-top: 8px;
  margin-left: 15px;
  .dropdown-menu {
    top: 100%;
    right: 0px;
    left: auto !important;
    width: 170px;
    border-radius: 0px;
    margin-top: 17px;
    background-color: #222222;
    border: none;
    padding: 0px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    border-top: 3px solid $color_secondary;
  }
}

.fixed-header .language .dropdown-menu {
  margin-top: 41px;
}

.main-header {
  .language > a {
    color: #222222;
    padding: 0px 0px;
    background: none !important;
    text-transform: uppercase;
    display: inline-block;
    border: 0px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1em;
    &:focus {
      outline: none;
    }
    &:after {
      display: none;
    }
  }
  language a:hover {
    background: none;
  }
  .language .dropdown-menu > li {
    padding-right: 0px !important;
    margin: 0px !important;
    float: none;
    display: block !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    &:last-child {
      border-bottom: none;
    }
    > a {
      padding: 14px 20px !important;
      color: #ffffff;
      display: block;
      text-align: left;
      -webkit-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      transition: all 300ms ease;
      &:hover {
        color: #ffffff !important;
        background-color: $color_secondary;
      }
    }
  }
}

/*Sticky Header*/

.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  background: #18191b;
  z-index: 0;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  .logo {
    padding: 5px 0px 5px;
  }
}

.main-header .sticky-header {
  .outer-box {
    margin-top: 20px;
  }
  .search-box-outer .dropdown-menu {
    top: 46px;
  }
}

.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.main-header {
  .header-upper {
    position: relative;
    z-index: 5;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    .logo img {
      position: relative;
      display: inline-block;
      max-width: 100%;
    }
  }
  .logo-outer {
    position: relative;
    z-index: 25;
    padding: 45px 0px 0px;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
  }
}

.fixed-header {
  .logo-outer {
    padding: 12px 0px;
  }
  .header-upper .upper-right {
    margin-top: 0px !important;
  }
}

.main-menu {
  position: relative;
  float: left;
  .navbar-collapse {
    padding: 0px;
  }
  .navigation {
    position: relative;
    margin: 0px;
    > li {
      position: relative;
      float: left;
      margin-left: 32px;
    }
  }
}

.main-menu .navigation > li:last-child {
  margin-right: 0px;
}

.main-header .sticky-header .main-menu .navigation > li {
  margin: 0px;
  border: 0px;
  margin-left: 30px;
}

.main-menu .navigation > li {
  > a {
    position: relative;
    display: block;
    font-size: 15px;
    color: #222222;
    line-height: 30px;
    font-weight: 400;
    opacity: 1;
    text-transform: capitalize;
    padding: 35px 0px 35px 0px;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    font-family: $font_primary, sans-serif;
  }
  &.dropdown > a {
    padding-right: 15px;
    &:after {
      font-family: 'FontAwesome';
      content: '\f107';
      position: absolute;
      right: 0px;
      top: 50%;
      height: 20px;
      line-height: 20px;
      margin-top: -10px;
      font-size: 15px;
      font-weight: normal;
      display: block;
      transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;
    }
  }
}

.main-menu .navigation > li {
  &:hover > a,
  &.current > a,
  &.current-menu-item > a {
    color: $color_secondary;
    opacity: 1;
  }
}

.main-header .sticky-header .main-menu .navigation > li {
  &:hover > a,
  &.current > a,
  &.current-menu-item > a {
    color: $color_secondary;
    opacity: 1;
  }
}

.main-menu .navigation > li:hover > a:after {
  opacity: 1;
}

.main-header .sticky-header .main-menu .navigation > li > ul {
  &:before {
    display: none;
  }
  top: 100% !important;
}

.main-menu .navigation > li > ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 220px;
  z-index: 100;
  display: none;
  background: #f6f6f6;
  border-top: 2px solid #ff4c72;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
  &.from-right {
    left: auto;
    right: 0px;
  }
  > li {
    position: relative;
    width: 100%;
    &:last-child {
      border-bottom: none;
    }
    > a {
      position: relative;
      display: block;
      padding: 12px 30px;
      line-height: 24px;
      font-weight: 500;
      font-size: 15px;
      color: #242424;
      text-align: left;
      padding-right: 0px !important;
      text-transform: capitalize;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      font-family: $font_primary, sans-serif;
      &:before {
        position: absolute;
        content: '';
        left: 15px;
        top: 50%;
        width: 7px;
        height: 1px;
        background-color: #242424;
        transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -webkit-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        font-family: 'FontAwesome';
      }
    }
    &:hover > a {
      &:before {
        background-color: #ffffff;
      }
      background-color: $color_secondary;
      color: #ffffff;
    }
    > ul {
      position: absolute;
      left: 100%;
      top: 0px;
      width: 220px;
      z-index: 100;
      display: none;
      background: #f6f6f6;
      border-top: 2px solid #ff4c72;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
        (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
        (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
        (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
        (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
        (-2px) 0px 5px 1px rgba(0, 0, 0, 0.05);
      &:before {
        position: absolute;
        content: '';
        right: 100%;
        top: 0px;
        width: 20px;
        height: 100%;
      }
      > li {
        position: relative;
        width: 100%;
        &:last-child {
          border-bottom: none;
        }
        > a {
          position: relative;
          display: block;
          padding: 12px 30px;
          line-height: 24px;
          font-weight: 500;
          font-size: 15px;
          color: #242424;
          text-align: left;
          padding-right: 0px !important;
          text-transform: capitalize;
          transition: all 500ms ease;
          -moz-transition: all 500ms ease;
          -webkit-transition: all 500ms ease;
          -ms-transition: all 500ms ease;
          -o-transition: all 500ms ease;
          font-family: $font_primary, sans-serif;
          &:before {
            position: absolute;
            content: '';
            left: 15px;
            top: 50%;
            width: 7px;
            height: 1px;
            background-color: #242424;
            transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -webkit-transition: all 500ms ease;
            -ms-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            font-family: 'FontAwesome';
          }
        }
        &:hover > a:before {
          background-color: #ffffff;
        }
        > a:hover {
          background-color: $color_secondary;
          color: #ffffff;
        }
      }
    }
  }
}

.sticky-header .main-menu .navigation > li > a:after {
  display: none;
}

.main-menu {
  .navigation {
    > li.dropdown:hover > ul {
      visibility: visible;
      opacity: 1;
    }
    li > ul > li.dropdown:hover > ul {
      visibility: visible;
      opacity: 1;
      left: 100%;
      top: 0%;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }
  }
  .navbar-collapse > ul li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 6px;
    width: 34px;
    height: 30px;
    border: 1px solid #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    cursor: pointer;
    z-index: 5;
    display: none;
  }
}

.main-header {
  .outer-box {
    position: relative;
    float: left;
    margin-left: 24px;
    margin-top: 34px;

    .nav-btn {
      position: relative;
      top: 3px;
      font-size: 21px;
      cursor: pointer;
      color: #222222;
      margin-left: 20px;
      display: inline-block;
    }
  }

  .header-lower {
    .search-box-outer {
      min-width: 60px;
    }

    .search-box-btn {
      position: relative;
      display: block;
      width: 100%;
      font-size: 18px;
      color: #ffffff;
      line-height: 20px !important;
      padding: 0px;
      margin: 0px;
      cursor: pointer;
      background: none;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }

    .search-box-outer .dropdown-menu {
      top: 44px;
      right: 0px;
      padding: 0px;
      width: 280px;
      border-radius: 0px;
      border-top: 3px solid $color_secondary;

      > li {
        padding: 0px;
        border: none;
        background: none;
      }
    }

    .search-panel {
      .form-container {
        padding: 25px 20px;
      }

      .form-group {
        position: relative;
        margin: 0px;
      }

      input {
        &[type='number'],
        &[type='text'],
        &[type='search'],
        &[type='password'] {
          display: block;
          width: 100%;
          height: 40px;
          color: #000000;
          line-height: 24px;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          padding: 7px 40px 7px 15px;
          -webkit-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          transition: all 300ms ease;
        }
      }

      select {
        display: block;
        width: 100%;
        height: 40px;
        color: #000000;
        line-height: 24px;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        padding: 7px 40px 7px 15px;
        -webkit-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        transition: all 300ms ease;
      }

      input:focus,
      select:focus {
        border-color: $color_secondary;
      }

      .search-btn {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 40px;
        height: 40px;
        text-align: center;
        color: #555555;
        font-size: 12px;
        background: none;
        cursor: pointer;
      }
    }
  }

  .search-box-outer {
    position: relative;
    left: 3px;
    display: inline-block;
  }

  .search-box-btn {
    position: relative;
    font-size: 15px;
    line-height: 1em;
    color: #222222;
    cursor: pointer;
    background: none;
    display: inline-block;

    &:after {
      display: none;
    }
  }

  .search-box-outer .dropdown-menu {
    top: 62px !important;
    padding: 0px;
    width: 280px;
    right: 0px;
    border-radius: 0px;
    left: auto !important;
    border-top: 3px solid $color_secondary;
    transform: translate3d(0px, 0px, 0px) !important;
  }

  .search-panel {
    .form-container {
      padding: 25px 20px;
    }

    .form-group {
      position: relative;
      margin: 0px;
    }

    input {
      &[type='number'],
      &[type='text'],
      &[type='search'],
      &[type='password'] {
        display: block;
        width: 100%;
        line-height: 24px;
        padding: 7px 40px 7px 15px;
        height: 40px;
        border: 1px solid #e0e0e0;
        background: #ffffff;
      }
    }

    select {
      display: block;
      width: 100%;
      line-height: 24px;
      padding: 7px 40px 7px 15px;
      height: 40px;
      border: 1px solid #e0e0e0;
      background: #ffffff;
    }

    input:focus,
    select:focus {
      border-color: $color_secondary;
    }

    .search-btn {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #555555;
      font-size: 12px;
      background: none;
      cursor: pointer;
    }
  }

  .info-box {
    position: relative;
    float: left;
    margin-left: 30px;
    font-size: 13px;
    color: #777777;
    text-align: left;
    padding: 0px 0px 0px 50px;

    &:first-child {
      padding-right: 30px;
      border-right: 1px solid #c8c8c8;
    }

    &:last-child {
      padding-right: 0px;
      margin-left: 0px;
      top: -2px;
    }

    .icon-box {
      position: absolute;
      left: 0px;
      top: 6px;
      color: $color_secondary;
      font-size: 26px;
      text-align: left;
      line-height: 1.2em;
      -moz-transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease;
    }

    li {
      position: relative;
      line-height: 20px;
      color: #767676;
      font-size: 15px;
      margin-bottom: 3px;
      font-weight: 500;
    }

    strong {
      font-weight: 500;
      color: #333333;
      font-size: 15px;
      display: block;
      margin-bottom: 4px;
    }
  }
}

/*search box btn*/

/*Custom Select*/

.form-group {
  .ui-selectmenu-button.ui-button {
    width: 100px;
    font-size: 18px;
    font-style: normal;
    height: 50px;
    padding: 10px 20px;
    line-height: 33px;
    color: #262626;
    float: left;
    border-radius: 0px;
    border: 1px solid #f2f2f2;
    background: #ffffff;
  }
  .ui-button .ui-icon {
    background: none;
    position: relative;
    top: 2px;
    text-indent: 0px;
    color: #333333;
    &:before {
      font-family: 'FontAwesome';
      content: '\f107';
      position: absolute;
      right: 0px;
      top: 2px !important;
      top: 13px;
      width: 10px;
      height: 30px;
      display: block;
      color: #262626;
      line-height: 23px;
      font-size: 15px;
      font-weight: normal;
      text-align: center;
      z-index: 5;
    }
  }
}

.ui-widget.ui-widget-content {
  border: 1px solid #e0e0e0;
}

.ui-menu .ui-menu-item {
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
  &:last-child {
    border: none;
  }
}

.ui-state-active,
.ui-widget-content .ui-state-active {
  background-color: $color_secondary;
  border-color: $color_secondary;
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: block;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 24px;
}

/***

  ====================================================================
      Section Title
  ====================================================================

  ***/

.sec-title {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  h2 {
    position: relative;
    font-size: 35px;
    color: #444444;
    font-weight: 400;
    line-height: 1.3em;
  }
  &.light h2 {
    color: #ffffff;
  }
  &.centered {
    text-align: center;
  }
}

/***

  ====================================================================
      Main Slider style
  ====================================================================

  ***/

.main-slider {
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #e0e0e0;
  .tp-caption {
    z-index: 5 !important;
  }
  .tp-dottedoverlay {
    background: rgba(0, 0, 0, 0.4) !important;
  }
  .tparrows.gyges {
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    &:before {
      line-height: 60px;
    }
    &:hover {
      color: $color_secondary !important;
      background-color: $color_secondary;
    }
  }
  h2 {
    position: relative;
    color: #ffffff;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.3em;
    display: inline-block;
  }
  .text {
    position: relative;
    font-size: 15px;
    font-weight: 400;
    color: #e7e7e7;
    line-height: 2em;
  }
  .btns-box {
    position: relative;
    z-index: 2;
    overflow: hidden;
    .theme-btn {
      position: relative;
      z-index: 2;
      margin-right: 20px;
    }
  }
  &:after {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 40%;
    border-bottom: 50px solid #ffffff;
    border-right: 750px solid transparent;
  }
  .tp-bannertimer,
  .tp-bullets {
    display: none !important;
  }
}

/***

  ====================================================================
      Fancy Box
  ====================================================================

  ***/

.fancybox-next span,
.fancybox-prev span {
  background-image: none !important;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  text-align: center;
}

.fancybox-next span:before,
.fancybox-prev span:before {
  content: '';
  position: absolute;
  font-family: 'FontAwesome';
  left: 0px;
  top: 0px;
  font-size: 12px;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  background-color: rgba(28, 28, 28, 0.4) !important;
  color: #ffffff;
  visibility: visible;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.fancybox-next span:before {
  content: '\f178';
}

.fancybox-prev span:before {
  content: '\f177';
}

.fancybox-next:hover span:before,
.fancybox-prev:hover span:before {
  background-color: #ffffff !important;
  color: #000000;
}

.fancybox-type-image {
  .fancybox-close {
    right: 0px;
    top: 0px;
    width: 45px;
    height: 45px;
    background: url(images/icons/icon-cross.png) center center no-repeat;
    background-color: rgba(17, 17, 17, 0.5) !important;
    &:hover {
      background-color: #000000 !important;
    }
  }
  .fancybox-skin {
    padding: 0px !important;
  }
}

/*Appointment Box*/

.appointment-box {
  position: fixed;
  right: -400px;
  top: 0px;
  width: 400px;
  max-width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.appointment-form-visible .appointment-box {
  right: 0px;
  opacity: 1;
  visibility: visible;
}

.appointment-box .inner-box {
  position: relative;
  background-color: #ff4c72;
  padding: 80px 30px 30px;
  .cross-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
  }
  h2 {
    position: relative;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.2em;
    color: #ffffff;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
  }
}

/*Appointment Form*/

.appointment-form {
  position: relative;

  .form-group {
    position: relative;
    margin-bottom: 15px;
  }

  input {
    &[type='number'],
    &[type='text'],
    &[type='email'] {
      position: relative;
      display: block;
      width: 100%;
      line-height: 23px;
      padding: 10px 20px;
      height: 48px;
      color: #ffffff;
      font-size: 16px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      background: none;
      transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
    }
  }

  textarea {
    position: relative;
    display: block;
    width: 100%;
    line-height: 23px;
    padding: 10px 20px;
    height: 48px;
    color: #ffffff;
    font-size: 16px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: none;
    transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
  }

  input::placeholder,
  textarea::placeholder {
    color: #ffffff;
  }
}

.contact-form.alternate {
  input::placeholder,
  textarea::placeholder {
    color: #ffffff !important;
  }
}

.appointment-form {
  textarea {
    height: 135px;
    resize: none;
  }
  .form-group button {
    margin-top: 15px;
  }
}

.contact-info-box {
  position: relative;
  padding-top: 50px;
  .info-list {
    position: relative;
    padding-bottom: 18px;
    margin-bottom: 25px;
    li {
      position: relative;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
      font-family: 'Poppins', sans-serif;
    }
    &:before {
      position: absolute;
      content: '';
      left: 0px;
      bottom: 0px;
      width: 50px;
      height: 1px;
      background-color: #ffffff;
    }
  }
  .social-list {
    position: relative;
    li {
      position: relative;
      width: 50%;
      float: left;
      margin-bottom: 6px;
      display: inline-block;
      a {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        &:hover {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}

.form-back-drop {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  z-index: 9990;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.appointment-form-visible .form-back-drop {
  opacity: 1;
  visibility: visible;
}

/***

  ====================================================================
      Services Section
  ====================================================================

  ***/

.services-section {
  position: relative;
  padding: 70px 0px 80px;
  z-index: 1;
  .sec-title {
    margin-bottom: 70px;
  }
}

/*.services-section.style-two:before{
      position:absolute;
      content:'';
      left:0px;
      top:-140px;
      width:100%;
      height:227px;
      background:url(images/background/4.png) no-repeat;
  }*/

.services-block {
  position: relative;
  margin-bottom: 30px;
  .inner-box {
    position: relative;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    padding: 30px 20px 45px;
    border: 1px solid #dedede;
    &:before {
      position: absolute;
      content: '';
      left: 0%;
      top: 0%;
      width: 0px;
      height: 100%;
      background-color: $color_bg_primary;
      -webkit-transition: all 1000ms ease;
      -moz-transition: all 100ms ease;
      -ms-transition: all 1000ms ease;
      -o-transition: all 1000ms ease;
      transition: all 1000ms ease;
    }
    &:hover {
      &::before {
        width: 100%;
      }
      border-color: $color_bg_primary;
    }
    .icon-box {
      position: relative;
      z-index: 1;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      display: inline-block;
      background: url(images/icons/icon-1.png) no-repeat;
    }
  }
  &:nth-child(2) .inner-box .icon-box {
    background: url(images/icons/icon-2.png) no-repeat;
  }
  &:nth-child(3) .inner-box .icon-box {
    background: url(images/icons/icon-3.png) no-repeat;
  }
  &:nth-child(4) .inner-box .icon-box {
    background: url(images/icons/icon-4.png) no-repeat;
  }
  .inner-box .icon-box .icon {
    position: relative;
    font-size: 44px;
    color: #fe7696;
  }
  &:nth-child(2) .inner-box .icon-box .icon {
    color: #4e79f5;
  }
  &:nth-child(3) .inner-box .icon-box .icon {
    color: #42bd2d;
  }
  &:nth-child(4) .inner-box .icon-box .icon {
    color: #b276f3;
  }
  .inner-box {
    h3 {
      position: relative;
      font-size: 18px;
      z-index: 1;
      font-weight: 600;
      text-transform: capitalize;
      a {
        position: relative;
        color: #444444;
      }
    }
    .text {
      position: relative;
      color: #7f8497;
      z-index: 1;
      font-size: 16px;
      line-height: 1.8em;
      margin-top: 10px;
    }
  }
}

/***

  ====================================================================
      App Section
  ====================================================================

  ***/

.app-section {
  position: relative;
  padding-top: 60px;
  padding-bottom: 150px;
  &.style-two {
    padding-bottom: 0px;
  }
  .image-column {
    position: relative;
    .inner-column {
      position: relative;
      text-align: center;
      .message-box {
        position: absolute;
        left: 0px;
        top: 105px;
        .box-inner {
          position: relative;
          width: 100%;
          max-width: 250px;
          text-align: center;
          border-radius: 10px;
          padding: 35px 25px 30px;
          background-color: #ffffff;
          box-shadow: (-5px) 5px 15px rgba(0, 0, 0, 0.1);
          .icon-one {
            position: absolute;
            right: 62px;
            top: -8px;
            font-size: 42px;
            color: #dadada;
            line-height: 1em;
          }
          .icon-two {
            position: relative;
            font-size: 26px;
            color: #ffffff;
            line-height: 1em;
            padding: 7px 10px;
            border-radius: 6px;
            display: inline-block;
            background-color: #ff5c35;
          }
          h3 {
            position: relative;
            color: #333333;
            font-size: 15px;
            margin-top: 16px;
            font-weight: 600;
            text-transform: uppercase;
          }
          .text {
            position: relative;
            color: #7f8497;
            font-size: 14px;
            margin-top: 8px;
            font-weight: 400;
          }
        }
      }
      .play-box {
        position: absolute;
        right: 55px;
        bottom: 130px;
        min-width: 195px;
        text-align: left;
        padding: 18px 30px;
        background-color: #f3d176;
        border-radius: 0px 0px 0px 30px;
        box-shadow: (-5px) 5px 15px rgba(0, 0, 0, 0.15);
        .box-inner {
          position: relative;
          color: #363636;
          font-size: 15px;
          line-height: 1.4em;
          padding-left: 60px;
          font-weight: 700;
          text-transform: uppercase;
          .play-btn {
            position: absolute;
            left: 0px;
            top: 0px;
            color: #000000;
            font-size: 42px;
            line-height: 1em;
            font-weight: 600;
          }
        }
      }
      .chat-icon {
        position: absolute;
        right: 0px;
      }
      &:before {
        position: absolute;
        content: '';
        right: 0px;
        top: -60px;
        width: 566px;
        height: 652px;
        z-index: -1;
        display: inline-block;
        background: url(images/resource/pattern-1.png) no-repeat;
      }
    }
  }
  .content-column {
    position: relative;
    .inner-column {
      position: relative;
      padding-left: 50px;
      padding-top: 100px;
      h2 {
        position: relative;
        color: #444444;
        font-size: 35px;
        line-height: 1.4em;
      }
      .text {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        line-height: 1.8em;
        margin-top: 25px;
        margin-bottom: 35px;
      }
    }
  }
}

/* Play Box */

/* Chat Icon */

/***

  ====================================================================
      Services Section Two
  ====================================================================

  ***/

.services-section-two {
  position: relative;
  padding: 115px 0px 70px;
  background-color: #ebf1fe;
  &.style-two {
    margin-bottom: 170px;
    &:before {
      position: absolute;
      content: '';
      left: 0px;
      top: -230px;
      width: 100%;
      height: 230px;
      background: url(images/background/2.png) repeat-x;
    }
    &:after {
      position: absolute;
      content: '';
      left: 0px;
      bottom: -181px;
      width: 100%;
      height: 181px;
      background: url(images/background/3.png) repeat-x;
    }
  }
}

.services-block-two {
  position: relative;
  padding-bottom: 40px;
  .inner-box {
    position: relative;
    .content-column {
      position: relative;
      margin-bottom: 40px;
      .inner-column {
        position: relative;
        padding-top: 90px;
        padding-right: 40px;
      }
      .icon-box {
        position: relative;
        .service-number {
          position: absolute;
          left: 0px;
          top: 165%;
          font-weight: 800;
          font-size: 85px;
          color: rgba(255, 255, 255, 0.5);
          font-family: $defaul_font, sans-serif;
        }
        .icon {
          position: relative;
          font-size: 54px;
          color: #25c611;
          line-height: 1em;
        }
      }
      h3 {
        position: relative;
        color: #444444;
        font-size: 25px;
        line-height: 1.3em;
        margin-top: 30px;
      }
      .text {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        line-height: 1.7em;
        margin-top: 30px;
      }
    }
  }
  .image-column {
    position: relative;
    .inner-column {
      position: relative;
      padding-left: 70px;
    }
  }
  &.style-two .inner-box .content-column .inner-column {
    padding-top: 50px;
  }
}

.services-block-three.style-two .inner-box .content-column .inner-column {
  padding-top: 50px;
}

.services-block-two.style-two .inner-box .content-column .icon-box .icon {
  color: $color_primary;
}

.services-block-three {
  &.style-two .inner-box .content-column .icon-box .icon {
    color: $color_primary;
  }
  position: relative;
  padding-bottom: 40px;
  .inner-box {
    position: relative;
    .content-column {
      position: relative;
      .inner-column {
        position: relative;
        padding-top: 90px;
        padding-left: 50px;
      }
      .icon-box {
        position: relative;
        .service-number {
          position: absolute;
          left: 0px;
          top: 165%;
          font-weight: 800;
          font-size: 85px;
          color: rgba(255, 255, 255, 0.5);
          font-family: $defaul_font, sans-serif;
        }
        .icon {
          position: relative;
          font-size: 54px;
          color: #25c611;
          line-height: 1em;
        }
      }
      h3 {
        position: relative;
        color: #444444;
        font-size: 25px;
        line-height: 1.3em;
        margin-top: 30px;
      }
      .text {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        line-height: 1.7em;
        margin-top: 30px;
      }
    }
  }
  .image-column {
    position: relative;
    .inner-column {
      position: relative;
    }
  }
}

/* Services Block Three */

/***

  ====================================================================
      Testimonial Section
  ====================================================================

  ***/

.testimonial-section {
  position: relative;
  padding-top: 75px;
  padding-bottom: 0px;
  &.style-two {
    padding: 120px 0px 100px;
  }
  .carousel-column {
    position: relative;
    .owl-nav {
      display: none;
    }
    .owl-dots {
      position: relative;
      margin-top: 30px;
      .owl-dot {
        position: relative;
        width: 14px;
        height: 6px;
        margin-right: 5px;
        border-radius: 4px;
        display: inline-block;
        border: 1px solid #cccccc;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        &.active,
        &:hover {
          width: 24px;
          border-color: $color_secondary;
          background-color: $color_secondary;
        }
      }
    }
    .inner-column {
      position: relative;
      padding-top: 40px;
      padding-right: 60px;
      h2 {
        position: relative;
        color: #444444;
        font-size: 35px;
        line-height: 1.3em;
      }
      .designation {
        position: relative;
        color: #777777;
        font-size: 15px;
        line-height: 1.3em;
        margin-top: 12px;
      }
      .quote-icon {
        position: relative;
        color: $color_primary;
        font-size: 36px;
        margin: 20px 0px 25px;
      }
      .text {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        line-height: 1.8em;
      }
      .big-letter {
        position: absolute;
        right: 85px;
        top: 50px;
        color: #f7f9ff;
        line-height: 1em;
        font-size: 400px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
      }
    }
  }
  .image-column {
    position: relative;
    .inner-column {
      position: relative;
      min-height: 580px;
      #particles-js {
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
      }
      .author-one {
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        overflow: hidden;
        width: 154px;
        height: 154px;
        margin-left: -77px;
        margin-top: -77px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          left: 5%;
          top: 42%;
        }
      }
      .author-two {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 94px;
        height: 94px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -47px;
        margin-top: -47px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          left: 20%;
          top: 15%;
        }
      }
      .author-three {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -40px;
        margin-top: -40px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          left: 45%;
          top: 10%;
        }
      }
      .author-four {
        position: absolute;
        right: 50%;
        top: 50%;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -25px;
        margin-top: -25px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          right: 30%;
          top: 0%;
        }
      }
      .author-five {
        position: absolute;
        right: 50%;
        top: 50%;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -36px;
        margin-top: -36px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          right: 15%;
          top: 14%;
        }
      }
      .author-six {
        position: absolute;
        right: 50%;
        top: 50%;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -36px;
        margin-top: -36px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          right: 30%;
          top: 30%;
        }
      }
      .author-seven {
        position: absolute;
        right: 50%;
        top: 50%;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -22px;
        margin-top: -22px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          right: -10%;
          top: 40%;
        }
      }
      .author-eight {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -50px;
        margin-top: -50px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          left: 35%;
          top: 48%;
        }
      }
      .author-nine {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        overflow: hidden;
        margin-top: -23px;
        margin-left: -23px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          left: 65%;
          top: 60%;
        }
      }
      .author-ten {
        position: absolute;
        right: 50%;
        top: 50%;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -29px;
        margin-top: -29px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          right: 0%;
          top: 66%;
        }
      }
      .author-eleven {
        position: absolute;
        left: 50%;
        bottom: 50%;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -29px;
        margin-top: -29px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        &.now-in-view {
          left: 25%;
          bottom: 10%;
        }
      }
    }
  }
}

/*.testimonial-section .image-column .inner-column .author-one{
      position:absolute;
      left:0%;
      top:42%;
      border-radius:50%;
      overflow:hidden;
      width:154px;
      height:154px;
  } */

/***

  ====================================================================
      Testimonial Section Two
  ====================================================================

  ***/

.testimonial-section-two {
  position: relative;
  padding: 120px 0px 100px;
  .carousel-column {
    position: relative;
    z-index: 1;
    .owl-nav {
      display: none;
    }
    .owl-dots {
      position: relative;
      margin-top: 30px;
      .owl-dot {
        position: relative;
        width: 14px;
        height: 6px;
        margin-right: 5px;
        border-radius: 4px;
        display: inline-block;
        border: 1px solid #cccccc;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        &.active,
        &:hover {
          width: 24px;
          border-color: $color_secondary;
          background-color: $color_secondary;
        }
      }
    }
    .inner-column {
      position: relative;
      padding-top: 40px;
      padding-right: 0px;
      h2 {
        position: relative;
        color: #444444;
        font-size: 35px;
        line-height: 1.3em;
      }
      .designation {
        position: relative;
        color: #777777;
        font-size: 15px;
        line-height: 1.3em;
        margin-top: 12px;
      }
      .quote-icon {
        position: relative;
        color: $color_primary;
        font-size: 36px;
        margin: 20px 0px 25px;
      }
      .text {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        line-height: 1.8em;
      }
      .big-letter {
        position: absolute;
        right: 85px;
        top: 100px;
        color: #f7f9ff;
        line-height: 1em;
        font-size: 400px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
      }
    }
  }
  .image-column {
    position: relative;
    text-align: center;
    .inner-column {
      position: relative;
      min-height: 340px;
      background: url(images/background/map.png) center center no-repeat;
      .circle-one {
        position: absolute;
        left: 70px;
        top: 0px;
        width: 380px;
        height: 380px;
        border-radius: 50%;
        border: 2px dashed #bdbbbc;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
      }
      .circle-two {
        position: absolute;
        left: 135px;
        top: 65px;
        width: 250px;
        height: 250px;
        border-radius: 50%;
        border: 2px dashed #bdbbbc;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
      }
      .author-one {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        width: 89px;
        height: 89px;
        border-radius: 50%;
        overflow: hidden;
        padding: 5px;
        margin-left: -44.5px;
        margin-top: -44.5px;
        background-color: #ffffff;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
        img {
          border-radius: 50%;
        }
        &.now-in-view {
          left: 23%;
          top: 13%;
        }
      }
      .author-two {
        position: absolute;
        content: '';
        right: 50%;
        top: 50%;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        overflow: hidden;
        padding: 5px;
        margin-right: -33px;
        margin-top: -33px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        background-color: #ffffff;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
        img {
          border-radius: 50%;
        }
        &.now-in-view {
          right: 25%;
          top: 30%;
        }
      }
      .author-three {
        position: absolute;
        content: '';
        left: 50%;
        bottom: 50%;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        overflow: hidden;
        padding: 5px;
        margin-left: -36px;
        margin-bottom: -36px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        background-color: #ffffff;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
        img {
          border-radius: 50%;
        }
        &.now-in-view {
          left: 12%;
          bottom: 0%;
          margin: 0px;
        }
      }
      .author-four {
        position: absolute;
        content: '';
        right: 50%;
        bottom: 50%;
        width: 44px;
        height: 43px;
        border-radius: 50%;
        overflow: hidden;
        padding: 5px;
        margin-right: -22px;
        margin-bottom: -22px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        background-color: #ffffff;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
        img {
          border-radius: 50%;
        }
        &.now-in-view {
          right: 28%;
          bottom: 10%;
        }
      }
      .author-five {
        position: absolute;
        content: '';
        left: 50%;
        bottom: 50%;
        width: 137px;
        height: 137px;
        border-radius: 50%;
        overflow: hidden;
        padding: 5px;
        margin-left: -68.5px;
        margin-bottom: -68.5px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        background-color: #ffffff;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
        img {
          border-radius: 50%;
        }
        &.now-in-view {
          left: 34%;
          bottom: 24%;
          margin: 0px;
        }
      }
      .icon {
        position: absolute;
        content: '';
        left: 50%;
        bottom: 50%;
        width: 44px;
        height: 43px;
        border-radius: 50%;
        overflow: hidden;
        padding: 5px;
        color: #ffffff;
        margin-left: -22px;
        margin-bottom: -22px;
        transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -webkit-transition: all 1200ms ease;
        -ms-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        background-color: #ffffff;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
        .fa {
          background-color: $color_secondary;
          color: #ffffff;
          width: 32px;
          height: 32px;
          line-height: 32px;
          border-radius: 50%;
          overflow: hidden;
          display: inline-block;
        }
        &.now-in-view {
          left: 9%;
          bottom: 40%;
          margin: 0px;
        }
      }
    }
  }
}

/* Circle One */

/* Author One */

/* Author Two */

/* Author Three */

/* Author Four */

/* Author Five */

/* Icon */

/***

  ====================================================================
      Pricing Section
  ====================================================================

  ***/

.pricing-section {
  position: relative;
  padding: 70px 0px 130px;
  .sec-title {
    margin-bottom: 70px;
  }
  &.style-two {
    padding-top: 130px;
  }
  .pricing-option {
    position: relative;
    text-align: center;
    margin-top: 60px;
    .option-inner {
      position: relative;
      display: inline-block;
      cursor: pointer;
    }
    span {
      &.monthly {
        position: relative;
        top: -5px;
        color: #333333;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        text-transform: capitalize;
      }
      &.yearly {
        position: relative;
        top: -4px;
        color: #333333;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        display: inline-block;
        text-transform: capitalize;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }
      &.icon {
        position: relative;
        top: 8px;
        width: 65px;
        height: 32px;
        margin: 0px 8px;
        display: inline-block;
        border-radius: 50px;
        border: 1px solid #d9d9d9;
        &:before {
          position: absolute;
          content: '';
          left: 2px;
          top: 2px;
          bottom: 1px;
          width: 25px;
          height: 25px;
          display: inline-block;
          border-radius: 50%;
          background-color: $color_secondary;
          -webkit-transition: all 300ms ease;
          transition: all 500ms ease;
          -webkit-transition: all 500ms ease;
          -ms-transition: all 500ms ease;
          -o-transition: all 500ms ease;
        }
      }
    }
    .option-inner.active span.icon:before {
      left: 36px;
    }
  }
  .pricing-inner-container {
    .yearly-package {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: auto;
      opacity: 0;
    }
    .monthly-package {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: auto;
      opacity: 0;
      position: relative;
      opacity: 1;
    }
    &.toggle-package {
      .yearly-package {
        position: relative;
        opacity: 1;
      }
      .monthly-package {
        position: absolute;
        opacity: 0;
      }
    }
  }
}

/* Price Block */

.price-block {
  position: relative;
  margin-bottom: 60px;
}

.pricing-section {
  .monthly-package .price-block,
  .yearly-package .price-block,
  .toggle-package .monthly-package .price-block {
    visibility: hidden;
    transform: scaleX(0.5);
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    transition: all 700ms ease;
  }
  .default-active .price-block,
  .monthly-package .price-block,
  .toggle-package .yearly-package .price-block {
    opacity: 1;
    visibility: visible;
    transform: scaleX(1);
  }
}

.price-block {
  .inner-box {
    position: relative;
    border-radius: 8px;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.1);
    .ribbon {
      position: absolute;
      right: 10px;
      top: 21px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
      padding: 8px 16px 8px 25px;
      display: inline-block;
      background-color: $color_primary;
      transform: rotate(-90deg);
      font-family: $font_primary, sans-serif;
      &:after {
        position: absolute;
        content: '';
        left: -1px;
        bottom: 0px;
        border-top: 22px solid transparent;
        border-left: 15px solid #ffffff;
        border-bottom: 22px solid transparent;
      }
    }
    .title-box {
      position: relative;
      text-align: center;
      padding: 30px 15px;
      border-bottom: 1px solid #e4e4e4;
      h2 {
        position: relative;
        color: #444444;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    .content-box {
      position: relative;
      text-align: center;
      padding: 25px 15px 0px;
      .price {
        position: relative;
        color: #444444;
        font-weight: 700;
        line-height: 1em;
        font-size: 44px;
        font-family: $font_primary, sans-serif;
        sub {
          position: relative;
          font-size: 22px;
          font-weight: 600;
        }
        span {
          font-size: 22px;
          font-weight: 600;
        }
      }
      .title {
        position: relative;
        color: #24cfb5;
        font-size: 15px;
        line-height: 1.3em;
        font-weight: 600;
        margin: 20px 0px 25px;
      }
    }
  }
  &:nth-child(2) .inner-box .content-box .title {
    color: #9a60fa;
  }
  &:nth-child(3) .inner-box .content-box .title {
    color: #4ca6fb;
  }
  &:nth-child(4) .inner-box .content-box .title {
    color: #25c611;
  }
  &:nth-child(5) .inner-box .content-box .title {
    color: #35ce3c;
  }
  &:nth-child(6) .inner-box .content-box .title {
    color: #fb4cf1;
  }
  .inner-box .content-box {
    .price-list {
      position: relative;
      margin-bottom: 45px;
      li {
        position: relative;
        color: #777777;
        font-size: 14px;
        margin-bottom: 8px;
        font-weight: 600;
        padding-left: 15px;
        font-family: $font_primary, sans-serif;
        &:last-child {
          margin-bottom: 0px;
        }
        .check {
          position: relative;
          color: #50e245;
          margin-right: 6px;
        }
        .remove {
          position: relative;
          color: #ff3044;
          margin-right: 6px;
        }
      }
    }
    .btn-box {
      position: relative;
      margin-bottom: -30px;
    }
  }
}

/***

  ====================================================================
      Sponsors Section
  ====================================================================

  ***/

.sponsors-section {
  position: relative;
  padding: 85px 0px 60px;
  background-color: #fbfbfb;
  .owl-item {
    text-align: center !important;
    img {
      display: inline-block;
      width: auto !important;
      max-height: 100% !important;
      opacity: 0.5;
      -webkit-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      transition: all 300ms ease;
      &:hover {
        opacity: 1;
      }
    }
  }
  .title-box {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
    h2 {
      position: relative;
      color: #444444;
      font-size: 35px;
      font-weight: 400;
      line-height: 1.3em;
    }
    .title {
      position: relative;
      color: #7f8497;
      font-size: 16px;
      margin-top: 25px;
    }
  }
  .owl-dots,
  .owl-nav {
    display: none;
  }
  &.style-two {
    padding-top: 0px;
    background-color: #ffffff;
  }
}

/***

  ====================================================================
      Download Section
  ====================================================================

  ***/

.download-section {
  position: relative;
  padding: 180px 0px 180px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  .circle-icons {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: block;
    .icon-one {
      position: absolute;
      left: 7%;
      top: 12%;
      width: 62px;
      height: 62px;
      background-color: #ffc34a;
      border-radius: 50px 50px 0px 50px;
    }
    .icon-two {
      position: absolute;
      left: 18%;
      top: 45%;
      width: 35px;
      height: 35px;
      background-color: #9f4aff;
      border-radius: 50px 50px 50px 0px;
    }
    .icon-three {
      position: absolute;
      left: 5%;
      bottom: 10%;
      width: 70px;
      height: 70px;
      background-color: #58e582;
      border-radius: 50px 50px 0px 50px;
    }
    .icon-four {
      position: absolute;
      left: 35%;
      top: 18%;
      width: 42px;
      height: 42px;
      transform: rotate(-20deg);
      -moz-transform: rotate(-20deg);
      -webkit-transform: rotate(-20deg);
      -ms-transform: rotate(-20deg);
      -o-transform: rotate(-20deg);
      background-color: #54bdff;
      border-radius: 0px 50px 50px 50px;
    }
    .icon-five {
      position: absolute;
      left: 35%;
      top: 45%;
      width: 35px;
      height: 35px;
      background-color: #ffc34a;
      border-radius: 50px 50px 0px 50px;
    }
    .icon-six {
      position: absolute;
      left: 30%;
      bottom: 10%;
      width: 32px;
      height: 32px;
      background-color: #ff4a5b;
      border-radius: 50px 50px 0px 50px;
    }
    .icon-seven {
      position: absolute;
      left: 44%;
      bottom: 7%;
      width: 60px;
      height: 60px;
      background-color: #524aee;
      border-radius: 50px 50px 50px 0px;
    }
    .icon-eight {
      position: absolute;
      left: 60%;
      bottom: 12%;
      width: 32px;
      height: 32px;
      transform: rotate(-20deg);
      -moz-transform: rotate(-20deg);
      -webkit-transform: rotate(-20deg);
      -ms-transform: rotate(-20deg);
      -o-transform: rotate(-20deg);
      background-color: #ff6131;
      border-radius: 50px 0px 50px 50px;
    }
    .icon-nine {
      position: absolute;
      left: 60%;
      top: 10%;
      width: 32px;
      height: 32px;
      transform: rotate(-20deg);
      -moz-transform: rotate(-20deg);
      -webkit-transform: rotate(-20deg);
      -ms-transform: rotate(-20deg);
      -o-transform: rotate(-20deg);
      background-color: #34f8e8;
      border-radius: 50px 50px 0px 50px;
    }
    .icon-ten {
      position: absolute;
      left: 68%;
      bottom: 12%;
      width: 60px;
      height: 60px;
      background-color: #cc6aff;
      border-radius: 50px 50px 50px 0px;
    }
    .icon-eleven {
      position: absolute;
      left: 70%;
      top: 20%;
      width: 47px;
      height: 47px;
      transform: rotate(-20deg);
      -moz-transform: rotate(-20deg);
      -webkit-transform: rotate(-20deg);
      -ms-transform: rotate(-20deg);
      -o-transform: rotate(-20deg);
      background-color: #0294f5;
      border-radius: 50px 50px 50px 0px;
    }
    .icon-twelve {
      position: absolute;
      right: 10%;
      top: 20%;
      width: 66px;
      height: 66px;
      transform: rotate(20deg);
      -moz-transform: rotate(20deg);
      -webkit-transform: rotate(20deg);
      -ms-transform: rotate(20deg);
      -o-transform: rotate(20deg);
      background-color: #5fdbea;
      border-radius: 50px 50px 0px 50px;
    }
    .icon-thirteen {
      position: absolute;
      right: 20%;
      top: 50%;
      width: 30px;
      height: 30px;
      transform: rotate(20deg);
      -moz-transform: rotate(20deg);
      -webkit-transform: rotate(20deg);
      -ms-transform: rotate(20deg);
      -o-transform: rotate(20deg);
      background-color: #f55d28;
      border-radius: 50px 50px 0px 50px;
    }
    .icon-fourten {
      position: absolute;
      right: 6%;
      bottom: 10%;
      width: 62px;
      height: 62px;
      background-color: #ffe34a;
      border-radius: 50px 50px 0px 50px;
    }
    .icon-fifteen {
      position: absolute;
      right: 20%;
      bottom: 6%;
      width: 30px;
      height: 30px;
      background-color: #4aff9f;
      border-radius: 50px 50px 0px 50px;
    }
  }
  .title-box {
    position: relative;
    text-align: center;
    margin-bottom: 35px;
    h2 {
      position: relative;
      color: #444444;
      font-weight: 600;
      font-size: 35px;
    }
    .text {
      position: relative;
      color: #7f8497;
      font-weight: 400;
      font-size: 16px;
      max-width: 830px;
      margin: 0 auto;
      margin-top: 20px;
      line-height: 2em;
    }
  }
  .buttons-box {
    position: relative;
    text-align: center;
    .theme-btn {
      margin: 0px 10px 20px;
      border-radius: 50px;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    }
  }
}

/***

  ====================================================================
      Faq Section
  ====================================================================

  ***/

.faq-section {
  position: relative;
  padding: 135px 0px 110px;
  .sec-title {
    position: relative;
    margin-bottom: 80px;
  }
  .owl-dots {
    display: none;
  }
  .owl-nav {
    position: absolute;
    left: 0px;
    top: 50%;
    width: 100%;
    margin-top: -15px;
    .owl-prev {
      position: absolute;
      left: -80px;
      color: #d0d0d0;
      font-size: 50px;
      font-weight: 400;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }
    .owl-next {
      position: absolute;
      right: -80px;
      color: #d0d0d0;
      font-size: 50px;
      font-weight: 400;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }
    .owl-prev:hover,
    .owl-next:hover {
      color: $color_primary;
    }
  }
}

/* Faq Block */

.faq-block {
  position: relative;
  margin-bottom: 30px;
  .inner-box {
    position: relative;
    text-align: center;
    border-radius: 8px;
    padding: 68px 30px 45px;
    border: 1px solid #dedede;
    h3 {
      position: relative;
      color: #444444;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
      font-family: $font_primary, sans-serif;
    }
    .text {
      position: relative;
      p {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        line-height: 1.8em;
        margin-bottom: 18px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

/***

  ====================================================================
      Main Footer
  ====================================================================

  ***/

.main-footer {
  position: relative;
  background-color: #f6f6f6;
  .button-box {
    position: relative;
    top: -28px;
    text-align: center;
  }
  .widgets-section {
    position: relative;
    padding: 80px 0px 30px;
  }
  .footer-column {
    position: relative;
    float: left;
    width: 20%;
    margin-bottom: 30px;
    h2 {
      position: relative;
      color: #444444;
      font-size: 17px;
      font-weight: 600;
      line-height: 1.3em;
      margin-bottom: 15px;
      font-family: $font_primary, sans-serif;
    }
    .footer-list {
      position: relative;
      li {
        position: relative;
        margin-bottom: 8px;
        a {
          position: relative;
          color: #7f8497;
          font-size: 15px;
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          &:hover {
            color: $color_primary;
          }
        }
      }
    }
  }
  .footer-bottom {
    position: relative;
    text-align: center;
    padding: 35px 0px 100px;
    border-top: 1px solid #d4d4d4;
    .logo {
      position: relative;
      margin-bottom: 20px;
    }
    .copyright {
      position: relative;
      color: #7f8497;
      font-size: 14px;
      margin-bottom: 16px;
    }
    .social-nav {
      position: relative;
      a {
        position: relative;
        margin: 0px 9px;
        color: #7f8497;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        &:hover {
          color: $color_primary;
        }
      }
    }
  }
}

/***

  ====================================================================
      Default Section
  ====================================================================

  ***/

.default-section {
  position: relative;
  padding: 70px 0px 100px;
  background-color: $color_bg_primary;
  &:before {
    position: absolute;
    content: '';
    left: 0px;
    top: -230px;
    width: 100%;
    height: 230px;
  }
}

.counter-section {
  position: relative;
  .inner-container {
    position: relative;
    padding-bottom: 30px;
  }
}

/*Fact Counter*/

.fact-counter {
  position: relative;
  .column {
    position: relative;
    z-index: 5;
    margin-bottom: 30px;
    .inner {
      position: relative;
      text-align: center;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }
  }
  .counter-column:nth-child(2) .count-outer {
    color: #25c611;
    .count-text {
      color: #25c611;
    }
  }
  .count-outer {
    position: relative;
    font-weight: 600;
    color: #ffffff;
    font-size: 53px;
    .count-text {
      position: relative;
      font-weight: 700;
      color: #ffffff;
      float: left;
      font-size: 53px;
      line-height: 1em;
      font-family: $font_primary, sans-serif;
    }
  }
  .column .counter-title {
    position: relative;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    margin-top: 28px;
    float: left;
    margin-left: 2px;
    text-transform: capitalize;
  }
}

.counter-section .content-column {
  position: relative;
  .inner-column {
    position: relative;
    .text {
      position: relative;
      color: #7f8497;
      font-size: 16px;
      line-height: 1.8em;
    }
  }
}

/***

  ====================================================================
      App Section Two
  ====================================================================

  ***/

.default-section .app-section-two {
  position: relative;
  padding-top: 100px;
  h2 {
    position: relative;
    color: #444444;
    font-size: 35px;
    font-weight: 400;
    line-height: 1.3em;
    margin-bottom: 35px;
  }
}

.app-section-two {
  .accordian-column {
    position: relative;
    z-index: 1;
    .inner-column {
      position: relative;
      padding-right: 30px;
    }
  }
  .image-column {
    position: relative;
    .inner-column {
      position: relative;
      padding-left: 125px;
      .icon-one {
        position: absolute;
        left: 8%;
        top: 62%;
        width: 31px;
        height: 29px;
        background: url(images/icons/icon-5.png) no-repeat;
      }
      .icon-two {
        position: absolute;
        left: 35%;
        top: -10%;
        width: 17px;
        height: 16px;
        background: url(images/icons/icon-6.png) no-repeat;
      }
      .icon-three {
        position: absolute;
        right: 30%;
        top: -20%;
        width: 31px;
        height: 29px;
        background: url(images/icons/icon-7.png) no-repeat;
      }
      .icon-four {
        position: absolute;
        right: -11%;
        bottom: 15%;
        width: 31px;
        height: 29px;
        background: url(images/icons/icon-8.png) no-repeat;
      }
      .icon-five {
        position: absolute;
        right: -12%;
        bottom: 36%;
        width: 16px;
        height: 15px;
        background: url(images/icons/icon-9.png) no-repeat;
      }
      .lock-icon {
        position: absolute;
        left: 0px;
        top: -100px;
        opacity: 0.4;
        font-size: 200px;
        color: #cfdeff;
        line-height: 1em;
      }
    }
    .image {
      position: relative;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    }
  }
}

/***

  ====================================================================
      Accordion Style
  ====================================================================

  ***/

.accordion-box {
  position: relative;
  .block {
    position: relative;
    margin-bottom: 10px;
    padding: 21px 35px;
    border-radius: 5px;
    border: 1px solid #c3cee5;
    transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    &.active-block {
      background-color: #ffffff;
    }
    &:nth-child(2) .icon-outer {
      color: #25c611;
    }
    &:nth-child(3) .icon-outer {
      color: #4e79f5;
    }
    &:nth-child(4) .icon-outer {
      color: #b276f3;
    }
    .acc-btn {
      position: relative;
      font-size: 18px;
      cursor: pointer;
      line-height: 30px;
      color: #444444;
      font-weight: 600;
      padding: 0px 0px 0px 40px;
      transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      font-family: $font_primary, sans-serif;
    }
    .icon-outer {
      position: absolute;
      left: 0px;
      top: 0px;
      font-size: 26px;
      color: #42bd2d;
      font-weight: 400;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      .icon {
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }
    }
    .acc-content {
      position: relative;
      display: none;
      .content-text {
        padding-bottom: 6px;
      }
      &.current {
        display: block;
      }
    }
    .content {
      position: relative;
      font-size: 14px;
      padding: 10px 0px 0px 0px;
      .text {
        margin-bottom: 0px;
        line-height: 1.9em;
        font-size: 16px;
        color: #7f8497;
      }
      p:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

/***

  ====================================================================
      Platform Section
  ====================================================================

  ***/

.platform-section {
  position: relative;
  .title-box {
    position: relative;
    text-align: center;
    margin-bottom: 130px;
    h2 {
      position: relative;
      color: #444444;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.2em;
    }
    .text {
      position: relative;
      color: #7f8497;
      font-size: 16px;
      line-height: 1.8em;
      max-width: 1010px;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
  .inner-container {
    position: relative;
    .image-column {
      text-align: center;
      &:nth-child(2) {
        transform: translateY(-45px);
      }
    }
    &:before {
      position: absolute;
      content: '';
      right: -30px;
      top: -60px;
      width: 100%;
      height: 369px;
      display: block;
      background: url(images/background/pattern-1.png) no-repeat;
      background-position: right top;
    }
    .image-column .image {
      position: relative;
      margin-bottom: 40px;
    }
    .button-box {
      text-align: center;
      margin-top: 60px;
    }
  }
}

/***

  ====================================================================
      Download Section Two
  ====================================================================

  ***/

.download-section-two {
  position: relative;
  padding: 125px 0px 125px;
  background-size: cover;
  background-repeat: no-repeat;
  .title-column {
    position: relative;
    .inner-column {
      position: relative;
    }
    h2 {
      position: relative;
      color: #444444;
      font-size: 35px;
      font-weight: 600;
    }
    .text {
      position: relative;
      color: #7f8497;
      font-size: 16px;
      line-height: 1.8em;
      margin-top: 15px;
    }
    .buttons-box {
      position: relative;
      margin-top: 35px;
      .theme-btn {
        margin-right: 20px;
        border-radius: 50px;
        margin-bottom: 15px;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .clients-column {
    position: relative;
    .inner-column {
      position: relative;
      padding-left: 30px;
    }
    position: relative;
    .clients {
      position: relative;
      li {
        position: relative;
        float: left;
        width: 33.33%;
        display: block;
        text-align: center;
        padding: 0px 5px;
        margin-bottom: 10px;
        display: inline-block;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        a {
          position: relative;
          display: block;
          background-color: #ffffff;
          border: 1px solid transparent;
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          &:hover {
            border-color: #25c611;
          }
        }
      }
    }
  }
}

/***

  ====================================================================
      News Section
  ====================================================================

  ***/

.news-section {
  position: relative;
  padding: 130px 0px 110px;
}

.news-block {
  position: relative;
  margin-bottom: 40px;
  .inner-box {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #dcdcdc;
    .image {
      position: relative;
      overflow: hidden;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(255, 97, 125, 0.5);
        content: '';
        -webkit-transition: -webkit-transform 0.6s;
        transition: transform 0.6s;
        -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
          translate3d(0, -100%, 0);
        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
          translate3d(0, -100%, 0);
      }
    }
    &:hover .image:before {
      -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
        translate3d(0, 100%, 0);
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
        translate3d(0, 100%, 0);
    }
    .image img {
      position: relative;
      width: 100%;
      display: block;
    }
    .lower-content {
      position: relative;
      padding: 30px 28px;
      .time {
        position: relative;
        color: #25c611;
        font-size: 13px;
        line-height: 1.3em;
      }
      h3 {
        position: relative;
        color: #444444;
        font-size: 22px;
        font-weight: 400;
        line-height: 1.4em;
        font-weight: 600;
        margin-top: 14px;
        a {
          position: relative;
          color: #444444;
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          &:hover {
            color: #25c611;
          }
        }
      }
      .text {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        line-height: 1.8em;
        margin-top: 12px;
      }
      .author-box {
        position: relative;
        margin-top: 18px;
        .author-inner {
          position: relative;
          padding-left: 70px;
          padding-top: 8px;
          .image {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 57px;
            height: 57px;
            overflow: hidden;
            border-radius: 50%;
          }
        }
        .admin {
          position: relative;
          color: #a6a6a6;
          font-size: 13px;
          line-height: 1.3em;
        }
        .author-name {
          position: relative;
          color: #222222;
          font-size: 17px;
          margin-top: 4px;
        }
      }
      .likes {
        position: relative;
        color: #cfcfcf;
        font-size: 34px;
        line-height: 1em;
        margin-top: 32px;
        display: inline-block;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        .total {
          position: absolute;
          right: -4px;
          top: -4px;
          width: 15px;
          height: 15px;
          color: #ffffff;
          font-size: 9px;
          text-align: center;
          line-height: 15px;
          border-radius: 50px;
          background-color: #25c611;
        }
        &:hover {
          color: #25c611;
        }
      }
    }
  }
}

/***

  ====================================================================
      Screenshots Section
  ====================================================================

  ***/

.screenshots-section {
  position: relative;
  padding: 70px 0px 110px;
  overflow: hidden;
  &:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 250px;
    width: 100%;
    height: 499px;
    background: url(images/background/5.png) repeat-x;
  }
  .carousel-container {
    position: relative;
    overflow: hidden;
  }
  .carousel-outer {
    position: relative;
    width: 284px;
    height: 578px;
    margin: 0 auto;
    padding: 70px 20px;
    background: url(images/resource/iphone-mockup.png) center top no-repeat;
    .owl-carousel {
      z-index: auto;
    }
    .mockup-layer {
      position: absolute;
      width: 285px;
      left: 0px;
      top: 0px;
      z-index: 20;
      &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 20px;
        height: 630px;
        background: url(images/resource/iphone-mockup.png) left top no-repeat;
        z-index: 20;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        width: 21px;
        height: 630px;
        background: url(images/resource/iphone-mockup.png) right top
          no-repeat;
        z-index: 20;
      }
    }
  }
  .owl-stage-outer {
    overflow: visible;
  }
  .slide {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  .active .slide {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  .slide .image img {
    width: 100%;
    display: block;
    border-radius: 5px;
    z-index: 99999999;
  }
  .owl-nav,
  .owl-dots {
    display: none;
  }
}

/***

  ====================================================================
      Price Page Section
  ====================================================================

  ***/

.price-page-section {
  position: relative;
  padding-top: 145px;
  padding-bottom: 80px;
  .sec-title {
    margin-bottom: 70px;
  }
  .price-block {
    margin-bottom: 100px;
  }
}

/***

  ====================================================================
      Faq Page Section
  ====================================================================

  ***/

.faq-page-section {
  position: relative;
  padding-top: 110px;
  padding-bottom: 90px;
  .outer-container {
    position: relative;
    padding: 0px 80px;
  }
  .faq-tabs {
    position: relative;
    .tab-btns {
      position: relative;
      z-index: 1;
      text-align: center;
      margin-bottom: 70px;
      .tab-btn {
        position: relative;
        top: 1px;
        display: inline-block;
        font-size: 20px;
        background: none;
        color: #484950;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        border-radius: 50px;
        margin: 15px 4px;
        padding: 13px 42px 13px;
        transition: all 500ms ease;
        border: 1px solid #dddddd;
        text-transform: capitalize;
        font-family: $font_primary, sans-serif;
        &:hover,
        &.active-btn {
          color: #ffffff;
          background: #25c611;
          border-color: #25c611;
        }
      }
    }
    .tabs-content {
      position: relative;
      .tab {
        position: relative;
        display: none;
        &.active-tab {
          display: block;
        }
        .content {
          position: relative;
          .faq-block {
            float: left;
            width: 20%;
            padding: 0px 15px;
          }
        }
      }
    }
  }
}

/***

  ====================================================================
      Sidebar Page Container
  ====================================================================

  ***/

.sidebar-page-container {
  position: relative;
  padding: 100px 0px 50px;
  .content-side,
  .sidebar-side {
    margin-bottom: 40px;
  }
}

/*Sidebar Widget*/

.sidebar-widget {
  position: relative;
  margin-bottom: 45px;
}

/*Sidebar Title*/

.sidebar-title {
  position: relative;
  margin-bottom: 20px;
  h2 {
    position: relative;
    color: #444444;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
  }
}

/*Search Box Widget*/

.search-box {
  position: relative;

  &.margin-bottom {
    margin-bottom: 5px;
  }

  .form-group {
    position: relative;
    margin: 0px;

    input {
      &[type='number'],
      &[type='text'],
      &[type='search'] {
        position: relative;
        display: block;
        font-size: 14px;
        color: #666666;
        line-height: 28px;
        padding: 10px 20px;
        padding-right: 60px;
        height: 76px;
        width: 100%;
        font-style: italic;
        border-radius: 5px;
        border: 1px solid #d8d8d8;
        background-color: #ffffff;
        transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -webkit-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
      }
    }

    button {
      position: absolute;
      right: 5px;
      top: 0px;
      width: 60px;
      height: 76px;
      line-height: 60px;
      text-align: center;
      display: block;
      font-size: 24px;
      color: #c8c8c8;
      background: none;
      cursor: pointer;
      font-weight: normal;
      font-weight: 400;
    }
  }
}

.category-widget ul {
  position: relative;
  li {
    position: relative;
    padding-bottom: 13px;
    margin-bottom: 14px;
    border-bottom: 1px solid #dddddd;
    &:last-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
      border: none;
    }
    a {
      position: relative;
      display: block;
      font-size: 16px;
      color: #7f8497;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      span {
        position: absolute;
        right: 0px;
      }
      &:hover {
        color: #25c611;
      }
    }
  }
}

/*Post Widget*/

.sidebar .popular-posts {
  .post {
    position: relative;
    font-size: 16px;
    color: #7f8497;
    margin-bottom: 15px;
    padding-bottom: 14px;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      margin-bottom: 0px;
      border: 0px;
      padding-bottom: 0px;
    }
    .post-thumb {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 83px;
      border-radius: 50%;
      overflow: hidden;
    }
    &:hover .post-thumb img {
      opacity: 0.7;
    }
    .post-thumb img {
      display: block;
      width: 100%;
      transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
    }
    .text {
      position: relative;
      top: -4px;
      font-size: 16px;
      margin: 0px 0px 0px;
      font-weight: 500;
      color: #7f8497;
      line-height: 1.8em;
      text-transform: capitalize;
      a {
        color: #7f8497;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }
    }
    a {
      color: #ff4c72;
      &:hover {
        color: #ff4c72;
      }
    }
  }
  .post-info {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: #ff4c72;
  }
}

/* Tags */

.tags a {
  position: relative;
  color: #7f8497;
  font-size: 16px;
  padding: 10px 26px;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 5px;
  display: inline-block;
  border: 1px solid #dadadf;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  &:hover {
    color: #ffffff;
    border-color: #25c611;
    background-color: #25c611;
  }
}

/* Tweet Widget */

.tweet-widget {
  position: relative;
}

.tweet-box {
  position: relative;
  padding-bottom: 22px;
  margin-bottom: 24px;
  border-bottom: 1px solid #dbdbe2;
  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }
  .tweet-title {
    position: relative;
    .tweet-reply {
      position: relative;
      color: #444444;
      font-size: 16px;
      font-weight: 500;
      font-family: 'Poppins', sans-serif;
      a {
        position: relative;
        color: #1da1f2;
      }
    }
    .tweet-date {
      position: relative;
      color: #9094a5;
      font-size: 13px;
    }
  }
  .tweet-content {
    position: relative;
    padding-left: 30px;
    margin-top: 28px;
    .icon {
      position: absolute;
      left: 0px;
      top: 4px;
      font-size: 22px;
      color: #1da1f2;
      line-height: 1em;
    }
    .text {
      position: relative;
      color: #7f8497;
      font-size: 16px;
      line-height: 1.8em;
      font-weight: 500;
      .purple {
        color: $color_primary;
      }
      .blue {
        color: #1da1f2;
      }
      .black {
        color: #000000;
      }
    }
  }
}

.meta-tags .widget-content {
  position: relative;
  border-radius: 8px;
  padding: 30px 30px 20px;
  border: 1px solid #d9d9d9;
  ul {
    position: relative;
    li {
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #d9d9d9;
      &:last-child {
        border: none;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
      a {
        position: relative;
        display: block;
        font-size: 16px;
        color: #7f8497;
        transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -webkit-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
      }
    }
  }
}

/* News Block Two */

.news-block-two {
  position: relative;
  margin-bottom: 100px;
  &:last-child {
    margin-bottom: 0px;
  }
  .inner-box {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    .image {
      position: relative;
      overflow: hidden;
      .post-time {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 1;
        color: #ffffff;
        font-size: 14px;
        padding: 9px 18px;
        border-radius: 4px;
        line-height: 1.3em;
        display: inline-block;
        background-color: $color_primary;
        &.style-two {
          background-color: #25c611;
        }
        &.style-three {
          background: none;
          border: 1px solid #ffffff;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 110%;
        height: 110%;
        z-index: 1;
        background: rgba(255, 97, 125, 0.5);
        content: '';
        -webkit-transition: -webkit-transform 0.6s;
        transition: transform 0.6s;
        -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
          translate3d(0, -100%, 0);
        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
          translate3d(0, -100%, 0);
      }
    }
    &:hover .image:before {
      -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
        translate3d(0, 100%, 0);
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
        translate3d(0, 100%, 0);
    }
    .image {
      img {
        position: relative;
        width: 100%;
        display: block;
        border-radius: 5px;
      }
      .overlay-box {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        display: block;
        text-align: center;
        border-radius: 5px;
        background-color: rgba(159, 112, 237, 0.8);
        .icon {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 95px;
          height: 95px;
          color: $color_primary;
          margin-left: -40px;
          text-align: center;
          border-radius: 50%;
          line-height: 95px;
          display: inline-block;
          padding-left: 4px;
          font-size: 28px;
          margin-top: -43px;
          z-index: 1;
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.2);
        }
      }
    }
    .owl-dots {
      display: none;
    }
    .owl-nav {
      position: absolute;
      left: 0px;
      top: 50%;
      width: 100%;
      margin-top: -23px;
      .owl-prev {
        position: absolute;
        left: 20px;
        top: 0px;
        width: 56px;
        height: 56px;
        color: #25c611;
        font-size: 20px;
        line-height: 56px;
        text-align: center;
        border-radius: 50%;
        background-color: #ffffff;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }
      .owl-next {
        position: absolute;
        right: 20px;
        top: 0px;
        width: 56px;
        height: 56px;
        color: #25c611;
        font-size: 20px;
        line-height: 56px;
        text-align: center;
        border-radius: 50%;
        background-color: #ffffff;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }
      .owl-prev:hover,
      .owl-next:hover {
        color: #ffffff;
        background-color: #25c611;
      }
    }
    .lower-content {
      position: relative;
      padding: 30px 0px 0px;
      h3 {
        position: relative;
        color: #444444;
        font-size: 30px;
        font-weight: 400;
        line-height: 1.4em;
        a {
          position: relative;
          color: #444444;
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          &:hover {
            color: #25c611;
          }
        }
      }
      .text {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        line-height: 1.8em;
        margin-top: 16px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e1e1eb;
      }
      .pull-right {
        padding-top: 6px;
      }
      .author-box {
        position: relative;
        float: left;
        .author-inner {
          position: relative;
          padding-left: 70px;
          padding-top: 8px;
          .image {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 57px;
            height: 57px;
            overflow: hidden;
            border-radius: 50%;
          }
        }
        .admin {
          position: relative;
          color: #a6a6a6;
          font-size: 13px;
          line-height: 1.3em;
        }
        .author-name {
          position: relative;
          color: #222222;
          font-size: 17px;
          margin-top: 4px;
        }
      }
      .likes {
        position: relative;
        color: #cfcfcf;
        float: left;
        margin-left: 14px;
        font-size: 34px;
        line-height: 1em;
        margin-top: 4px;
        display: inline-block;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        .total {
          position: absolute;
          right: -4px;
          top: -4px;
          width: 15px;
          height: 15px;
          color: #ffffff;
          font-size: 9px;
          text-align: center;
          line-height: 15px;
          border-radius: 50px;
          background-color: #25c611;
        }
        &:hover {
          color: #25c611;
        }
      }
      .social-icons {
        position: relative;
        float: left;
        a {
          position: relative;
          width: 38px;
          height: 38px;
          color: #7f8497;
          font-size: 16px;
          line-height: 36px;
          text-align: center;
          display: inline-block;
          border-radius: 50px;
          margin-left: 5px;
          border: 1px solid #d6d6d6;
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          &:hover {
            background-color: #1da1f2;
            color: #ffffff;
            border-color: #1da1f2;
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
          }
        }
      }
      .times {
        position: relative;
        float: left;
        color: #7f8497;
        font-size: 15px;
        margin: 7px 0px 0px 15px;
      }
      .post-date {
        position: relative;
        margin-bottom: 24px;
        z-index: 1;
        color: #25c611;
        font-size: 14px;
        padding: 9px 18px;
        border-radius: 4px;
        line-height: 1.3em;
        display: inline-block;
        background-color: #ffffff;
      }
    }
  }
  &.style-two .inner-box {
    background-color: #a5ffd6;
    .lower-content {
      padding: 40px 30px;
      .text {
        color: #444444;
        border-color: rgba(255, 255, 255, 0.9);
      }
      .social-icons a {
        border-color: #ffffff;
        background-color: #ffffff;
        &:hover {
          background-color: #1da1f2;
          border-color: #1da1f2;
        }
      }
      .likes {
        color: #b9bcc6;
      }
    }
  }
}

.blog-masonry .styled-pagination {
  margin-top: 25px;
}

/*Pagination*/

.styled-pagination {
  position: relative;
  display: inline-block;
  li {
    position: relative;
    display: inline-block;
    margin: 0px 6px 10px 0px;
    a {
      position: relative;
      display: inline-block;
      line-height: 50px;
      height: 52px;
      font-size: 15px;
      min-width: 52px;
      color: #7f8497;
      font-weight: 400;
      text-align: center;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dfdfdf;
      text-transform: capitalize;
      transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
    }
    &.next a,
    &.prev a {
      width: 72px;
    }
    a {
      &:hover,
      &.active {
        color: #ffffff;
        border-color: #25c611;
        background-color: #25c611;
      }
    }
  }
}

/* Blog Single */

.blog-single {
  position: relative;
  .inner-box {
    position: relative;
    .image {
      position: relative;
      img {
        position: relative;
        width: 100%;
        display: block;
        border-radius: 5px;
      }
      .post-time {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 1;
        color: #ffffff;
        font-size: 14px;
        padding: 9px 18px;
        border-radius: 4px;
        line-height: 1.3em;
        display: inline-block;
        background-color: $color_primary;
      }
    }
    .lower-content {
      position: relative;
      padding-top: 32px;
      h2 {
        position: relative;
        color: #444444;
        font-size: 30px;
        font-weight: 400;
        line-height: 1.3em;
        margin-bottom: 18px;
      }
      p {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        line-height: 1.7em;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      blockquote {
        position: relative;
        border: none;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 55px;
        padding: 35px 80px 45px;
        background-color: #a5ffd6;
        .quote-icon {
          position: relative;
          color: #ffffff;
          font-size: 34px;
          line-height: 1em;
          margin-bottom: 15px;
        }
        .quote-text {
          position: relative;
          color: #444444;
          font-size: 20px;
          line-height: 1.6em;
        }
        .quote-author {
          position: relative;
          margin-top: 25px;
          padding-top: 15px;
          color: #222222;
          font-size: 16px;
          font-weight: 600;
          display: inline-block;
          &:before {
            position: absolute;
            content: '';
            left: 50%;
            top: 0px;
            width: 30px;
            height: 1px;
            margin-left: -15px;
            background-color: #737373;
          }
        }
      }
      .post-share-options {
        position: relative;
        padding-top: 25px;
        margin-top: 35px;
        border-top: 2px solid #f1f1f6;
        .social-icons {
          position: relative;
          a {
            position: relative;
            width: 38px;
            height: 38px;
            color: #7f8497;
            font-size: 16px;
            line-height: 36px;
            text-align: center;
            display: inline-block;
            border-radius: 50px;
            margin-left: 5px;
            border: 1px solid #d6d6d6;
            transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            &:hover {
              background-color: #1da1f2;
              color: #ffffff;
              border-color: #1da1f2;
              box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
            }
          }
        }
        .like-box {
          position: relative;
          .likes {
            position: relative;
            color: #cfcfcf;
            float: left;
            margin-left: 14px;
            font-size: 34px;
            line-height: 1em;
            margin-top: 4px;
            display: inline-block;
            transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            .total {
              position: absolute;
              right: -4px;
              top: -4px;
              width: 15px;
              height: 15px;
              color: #ffffff;
              font-size: 9px;
              text-align: center;
              line-height: 15px;
              border-radius: 50px;
              background-color: #25c611;
            }
          }
          .times {
            position: relative;
            float: left;
            color: #7f8497;
            font-size: 15px;
            margin: 7px 0px 0px 15px;
          }
        }
      }
    }
  }
}

/***

  ====================================================================
      Comments Area
  ====================================================================

   ***/

.sidebar-page-container {
  .comments-area {
    position: relative;
    margin-bottom: 50px;
    padding-top: 55px;
  }
  .group-title {
    position: relative;
    margin-bottom: 50px;
    h2 {
      font-size: 30px;
      color: #444444;
      font-weight: 400;
      text-transform: capitalize;
    }
    .title {
      position: relative;
      color: #7f8497;
      font-size: 16px;
      margin-top: 8px;
    }
  }
  .comments-area {
    .comment-box {
      position: relative;
      padding: 0px 0px 10px;
      margin-bottom: 32px;
      border-bottom: 1px solid #e4e4e4;
      -webkit-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      transition: all 300ms ease;
      &:last-child {
        margin-bottom: 0px;
        border: none;
        padding-bottom: 0px;
      }
      &:nth-child(1) {
        padding-top: 0px;
      }
      &:hover {
        border-color: #25c611;
      }
    }
    .comment {
      position: relative;
      padding: 0px 0px 0px 120px;
      font-size: 14px;
    }
    .reply-comment {
      margin-left: 110px;
      margin-right: 0px;
    }
    .comment-box {
      .author-thumb {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 98px;
        height: 98px;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 25px;
        transform: perspective(100px) rotateY(15deg);
        -webkit-transform: perspective(100px) rotateY(15deg);
        -ms-transform: perspective(100px) rotateY(15deg);
        -o-transform: perspective(100px) rotateY(15deg);
        -moz-transform: perspective(100px) rotateY(15deg);
      }
      &.reply-comment .author-thumb {
        transform: perspective(100px) rotateY(-15deg);
        -webkit-transform: perspective(100px) rotateY(-15deg);
        -ms-transform: perspective(100px) rotateY(-15deg);
        -o-transform: perspective(100px) rotateY(-15deg);
        -moz-transform: perspective(100px) rotateY(-15deg);
      }
      .author-thumb img {
        width: 100%;
        display: block;
        border-radius: 5px;
      }
    }
    .comment-info {
      margin-bottom: 10px;
      color: #3e5773;
      line-height: 24px;
      font-size: 13px;
    }
    .comment-box {
      strong {
        font-size: 17px;
        font-weight: 600;
        color: #222222;
        display: block;
        line-height: 1.2em;
        text-transform: capitalize;
        span {
          color: #25c611;
          font-size: 14px;
        }
      }
      .text {
        margin-bottom: 20px;
        color: #7f8497;
        font-size: 16px;
      }
    }
    .comment-info .comment-time {
      font-size: 15px;
      color: #222222;
      font-weight: 400;
      margin-top: 6px;
    }
    .comment-box .reply-btn {
      position: absolute;
      right: 0px;
      top: 0px;
      font-size: 13px;
      color: #a1a0a0;
      font-weight: 600;
      letter-spacing: 1px;
      padding: 5px 22px 3px;
      border-radius: 50px;
      display: inline-block;
      border: 1px solid #cfcfcf;
      text-transform: capitalize;
      -webkit-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      transition: all 300ms ease;
      &:hover {
        color: #ffffff;
        border-color: $color_primary;
        background-color: $color_primary;
      }
    }
    .reply-comment:last-child {
      border-bottom: none;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
}

/***

  ====================================================================
      Comment Form
  ====================================================================

   ***/

.comment-form {
  .form-group {
    position: relative;
    margin-bottom: 25px;
    input {
      &[type='text'],
      &[type='password'],
      &[type='tel'],
      &[type='email'] {
        position: relative;
        display: block;
        width: 100%;
        height: 52px;
        font-size: 15px;
        color: #000000;
        line-height: 32px;
        padding: 10px 0px;
        font-weight: 400;
        background: none;
        border-bottom: 1px solid #bababa;
        transition: all 500ms ease;
        -webkit-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
      }
    }
    select {
      position: relative;
      display: block;
      width: 100%;
      height: 52px;
      font-size: 15px;
      color: #000000;
      line-height: 32px;
      padding: 10px 0px;
      font-weight: 400;
      background: none;
      border-bottom: 1px solid #bababa;
      transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }
    textarea {
      position: relative;
      display: block;
      width: 100%;
      height: 110px;
      font-size: 15px;
      color: #000000;
      line-height: 32px;
      padding: 10px 0px;
      font-weight: 400;
      resize: none;
      background: none;
      border-bottom: 1px solid #bababa;
      transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      &::-webkit-input-placeholder {
        color: #262626;
      }
    }
    input::-webkit-input-placeholder {
      color: #262626;
    }
  }
  .theme-btn {
    margin-top: 10px;
  }
}

/***

  ====================================================================
      Contact Page Section
  ====================================================================

   ***/

.contact-page-section {
  position: relative;
  padding-top: 100px;
  .auto-container {
    position: relative;
    max-width: 1880px;
    margin: 0 auto;
  }
  .map-column {
    position: relative;
    .inner-column {
      position: relative;
      padding-right: 30px;
      .map-canvas {
        position: relative;
        &:before {
          position: absolute;
          content: '';
          left: 0px;
          right: -2px;
          bottom: -2px;
          top: 0px;
          z-index: 1;
          background: url(images/background/map-pattern.png) no-repeat;
          background-size: cover;
          background-position: right top;
        }
      }
    }
  }
  .form-column {
    position: relative;
    .inner-column {
      position: relative;
      padding-top: 30px;
      padding-left: 30px;
    }
    .title-box {
      position: relative;
      margin-bottom: 25px;
      h2 {
        position: relative;
        font-size: 30px;
        color: #444444;
        font-weight: 400;
        text-transform: capitalize;
      }
      .title {
        position: relative;
        color: #7f8497;
        font-size: 16px;
        margin-top: 8px;
      }
    }
  }
}

/*Map Data*/

.map-data {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  h6 {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
    color: #121212;
  }
}

.map-canvas {
  height: 580px;
}

/***

  ====================================================================
      Contact Form
  ====================================================================

   ***/

.contact-form {
  .form-group {
    position: relative;
    margin-bottom: 25px;
    input {
      &[type='text'],
      &[type='password'],
      &[type='tel'],
      &[type='email'] {
        position: relative;
        display: block;
        width: 100%;
        height: 52px;
        font-size: 15px;
        color: #000000;
        line-height: 32px;
        padding: 10px 0px;
        font-weight: 400;
        background: none;
        border-bottom: 1px solid #bababa;
        transition: all 500ms ease;
        -webkit-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
      }
    }
    select {
      position: relative;
      display: block;
      width: 100%;
      height: 52px;
      font-size: 15px;
      color: #000000;
      line-height: 32px;
      padding: 10px 0px;
      font-weight: 400;
      background: none;
      border-bottom: 1px solid #bababa;
      transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }
    textarea {
      position: relative;
      display: block;
      width: 100%;
      height: 110px;
      font-size: 15px;
      color: #000000;
      line-height: 32px;
      padding: 10px 0px;
      font-weight: 400;
      resize: none;
      background: none;
      border-bottom: 1px solid #bababa;
      transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      &::-webkit-input-placeholder {
        color: #262626;
      }
    }
    input::-webkit-input-placeholder {
      color: #262626;
    }
  }
  .theme-btn {
    margin-top: 10px;
  }
  input.error,
  select.error,
  textarea.error {
    border-color: #ff0000;
  }
  label.error {
    display: block;
    line-height: 24px;
    padding: 5px 0px 0px;
    margin: 0px;
    text-transform: uppercase;
    font-size: 11px;
    color: #ff0000;
    font-weight: 500;
  }
}

/* Contact Info Section */

.contact-info-section {
  position: relative;
  padding-top: 90px;
  padding-bottom: 90px;
}

.info-block {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  .block-inner {
    position: relative;
    text-align: center;
    display: inline-block;
    h3 {
      position: relative;
      color: #25c611;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    ul {
      position: relative;
      margin-bottom: 18px;
      padding-bottom: 18px;
      border-bottom: 2px solid #e8e8ef;
      li {
        position: relative;
        color: #7f8497;
        font-size: 15px;
        margin-bottom: 2px;
      }
    }
    .social-icons {
      position: relative;
      a {
        position: relative;
        width: 38px;
        height: 38px;
        color: #7f8497;
        font-size: 16px;
        line-height: 36px;
        text-align: center;
        display: inline-block;
        border-radius: 50px;
        margin-left: 5px;
        margin-right: 5px;
        border: 1px solid #d6d6d6;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        &:hover {
          background-color: $color_primary;
          color: #ffffff;
          border-color: $color_primary;
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}
