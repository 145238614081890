@font-face {
  font-family: 'LEMON MILK';
  src: url('webfonts/LEMONMILK-Bold.eot');
  src: url('webfonts/LEMONMILK-Bold.eot?#iefix') format('embedded-opentype'),
    url('webfonts/LEMONMILK-Bold.woff2') format('woff2'),
    url('webfonts/LEMONMILK-Bold.woff') format('woff'),
    url('webfonts/LEMONMILK-Bold.ttf') format('truetype'),
    url('webfonts/LEMONMILK-Bold.svg#LEMONMILK-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LEMON MILK';
  src: url('webfonts/LEMONMILK-Light.eot');
  src: url('webfonts/LEMONMILK-Light.eot?#iefix') format('embedded-opentype'),
    url('webfonts/LEMONMILK-Light.woff2') format('woff2'),
    url('webfonts/LEMONMILK-Light.woff') format('woff'),
    url('webfonts/LEMONMILK-Light.ttf') format('truetype'),
    url('webfonts/LEMONMILK-Light.svg#LEMONMILK-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

$defaul_font: 'Open Sans';
$font_primary: 'LEMON MILK';
